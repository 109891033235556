import React from 'react';
import {Link, Redirect} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import AuthVerification from "./../utils/AuthVerification";

const useStyles = makeStyles((theme) => ({
        home: {
            marginTop: 50
        },
        container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
        },
        buttons: {
            marginTop: 10
        }
    }
));

function Home() {
    let isAuthenticated = AuthVerification();

    const classes = useStyles();

    return (
        <>
            {isAuthenticated ? <Redirect to='/dashboard' /> : ''}
            <div className={classes.home}>
                <Container maxWidth="sm" className={classes.container}>
                    <Typography align="center" variant="h4" component="h1">Quiz App by Yves Schlimpert</Typography>
                    <ButtonGroup className={classes.buttons} size="large" color="primary" aria-label="large outlined primary button group">
                        <Button><Link to={'/register'}>Register</Link></Button>
                        <Button><Link to={'/login'}>Login</Link></Button>
                    </ButtonGroup>
                </Container>
            </div>
        </>
    );
}

export default Home;