import {createMuiTheme} from '@material-ui/core/styles';
import './style.css';
import 'fontsource-roboto';

const Theme = createMuiTheme({
    palette: {
        type: 'light'
    },
});

export default Theme;