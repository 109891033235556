import React from 'react';
import {Redirect} from 'react-router-dom';
import {useState} from 'react';
import { useCookies } from "react-cookie";
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import {Link} from "react-router-dom";
import axios from 'axios';
import CustomAlert from "../../utils/CustomAlert";
import AuthVerification from "../../utils/AuthVerification";

const useStyles = makeStyles((theme) => ({
        login: {
            marginTop: 50
        },
        heading: {
            marginBottom: 20
        },
        input: {
            width: '100%',
            marginBottom: 20
        },
        submit: {
            width: '100%',
            marginBottom: 30
        },
        forgotPassword: {
            marginBottom: 20
        },
        customAlert: {
            marginBottom: 20
        }
    }
));

function Login() {
    let isAuthenticated = AuthVerification();

    const classes = useStyles();

    let [email, setEmail] = useState('');
    let [password, setPassword] = useState('');
    let [alertStatus, setAlertStatus] = useState(false);
    let [alert, setAlert] = useState({});
    let [redirect, setRedirect] = useState(false);

    // eslint-disable-next-line
    const [authCookie, setAuthCookie] = useCookies(['auth']);

    function handleUserCookie(token) {
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 7);

        setAuthCookie('auth', token, {
            path: '/',
            expires: expirationDate
        });
    }

    async function login(e) {
        e.preventDefault();

        try {
            const loginData = {
                email,
                password
            };

            const response = await axios.post(process.env.REACT_APP_LOGIN_URL, loginData);

            const authToken = 'Bearer ' + response.data.token;
            handleUserCookie(authToken);

            setRedirect(true);
        } catch (err) {
            const alertObject = {
                'text': err.response.data.error,
                'status': 'error'
            };

            setAlert(alertObject);
            setAlertStatus(true);
        }
    }

    return (
        <>
            {redirect || isAuthenticated ? <Redirect to='/dashboard' /> : ''}
            <div className={classes.login}>
                <Container maxWidth="sm">
                    {alertStatus ? <CustomAlert text={alert.text} status={alert.status}/> : ''}
                    <Typography className={classes.heading} align="center" variant="h4" component="h1">Login to your account</Typography>
                    <form onSubmit={login}>
                        <TextField required
                                   className={classes.input}
                                   type="email"
                                   label="Email"
                                   value={email}
                                   onChange={(e) => setEmail(e.target.value)}/>
                        <TextField required
                                   className={classes.input}
                                   type="password"
                                   label="Password"
                                   value={password}
                                   minLength="8"
                                   onChange={(e) => setPassword(e.target.value)}/>
                        <Typography variant="body1" align="center" className={classes.forgotPassword} gutterBottom>
                            <Link to={'/forgot-password'}>Forgot your password?</Link>
                        </Typography>
                        <Button variant="contained" className={classes.submit} color="secondary" type="submit">Login</Button>
                    </form>
                    <Typography variant="body1" align="center" gutterBottom>
                        You don't have an account?<br/>
                        <Link to={'/register'}>Register</Link>
                    </Typography>
                </Container>
            </div>
        </>
    );
}

export default Login;