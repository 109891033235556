import React, {useState} from 'react';
import Cookies from 'js-cookie';
import {makeStyles} from "@material-ui/core/styles";
import AuthVerification from "../../utils/AuthVerification";
import {Redirect} from "react-router-dom";
import axios from "axios";
import QuizList from "../quiz/QuizList";
import Button from '@material-ui/core/Button';
import OwnQuizList from "../quiz/OwnQuizList";
import {Add} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
        dashboard: {
            marginTop: 50
        },
        button: {
            width: '100%',
            marginBottom: 40
        }
    }
));

function Dashboard() {
    let isAuthenticated = AuthVerification();
    const classes = useStyles();

    let [ownQuizzesLoaded, setOwnQuizzesLoaded] = useState(false);
    let [quizzesLoaded, setQuizzesLoaded] = useState(false);
    let [ownLoadedQuizzes, setOwnLoadedQuizzes] = useState([]);
    let [loadedQuizzes, setLoadedQuizzes] = useState([]);
    let [addRedirect, setAddRedirect] = useState(false);
    let [editRedirect, setEditRedirect] = useState(false);
    let [editRedirectId, setEditRedirectId] = useState('');
    let [quizItRedirect, setQuizItRedirect] = useState(false);
    let [quizItRedirectId, setQuizItRedirectId] = useState('');
    let [quizResultsRedirect, setQuizResultsRedirect] = useState(false);
    let [quizResultsRedirectId, setQuizResultsRedirectId] = useState('');

    async function getAllQuizzes() {
        try {
            setQuizzesLoaded(true);
            const quizzes = await axios.get(process.env.REACT_APP_GET_ALL_QUIZZES_URL, {
                headers: {
                    Authorization: Cookies.get('auth')
                }
            });

            setLoadedQuizzes(quizzes.data.data);
        } catch (err) {
            console.log(err);
        }
    }

    async function getOwnQuizzes() {
        try {
            setOwnQuizzesLoaded(true);
            const quizzes = await axios.get(process.env.REACT_APP_GET_OWN_QUIZZES_URL, {
                headers: {
                    Authorization: Cookies.get('auth')
                }
            });

            setOwnLoadedQuizzes(quizzes.data.data);
        } catch (err) {
            console.log(err);
        }
    }

    async function deleteQuiz(id) {
        try {
            await axios.delete(process.env.REACT_APP_DELETE_QUIZ_URL + '/' + id, {
                headers: {
                    Authorization: Cookies.get('auth')
                }
            });

            getOwnQuizzes();
            getAllQuizzes();
        } catch (err) {
            console.log(err);
        }
    }

    function editQuiz(id) {
        setEditRedirectId(id);
        setEditRedirect(true);
    }

    function quizIt(id) {
        setQuizItRedirectId(id);
        setQuizItRedirect(true);
    }

    function viewResults(id) {
        setQuizResultsRedirectId(id);
        setQuizResultsRedirect(true);
    }

    if (!quizzesLoaded) {
        getAllQuizzes();
    }

    if (!ownQuizzesLoaded) {
        getOwnQuizzes();
    }

    return (
        <>
            {!isAuthenticated ? <Redirect to='/login'/> : ''}
            {editRedirect ? <Redirect to={'/edit-quiz/' + editRedirectId}/> : ''}
            {quizItRedirect ? <Redirect to={'/quiz-it/' + quizItRedirectId}/> : ''}
            {quizResultsRedirect ? <Redirect to={'/results/' + quizResultsRedirectId}/> : ''}
            {addRedirect ? <Redirect to='/add-quiz'/> : ''}
            <div className={classes.dashboard}>
                <Button
                    className={classes.button}
                    variant="contained"
                    color="secondary"
                    onClick={() => setAddRedirect(true)}
                    startIcon={<Add/>}>Create a new Quiz!</Button>
                <OwnQuizList
                    title="Own Quizzes"
                    quizzes={ownLoadedQuizzes}
                    editQuiz={editQuiz}
                    viewResults={viewResults}
                    deleteQuiz={deleteQuiz}
                />
                <QuizList title="All Quizzes"
                          quizzes={loadedQuizzes}
                          quizIt={quizIt}
                />
            </div>
        </>
    )
}

export default Dashboard;