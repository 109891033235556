import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import CustomAlert from "../../utils/CustomAlert";
import {FormatColorText} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
        heading: {
            marginBottom: 20
        },
        quizlist: {
            marginBottom: 40
        },
        error: {
            width: '100%',
            display: 'block'
        }
    }
));

function QuizList(props) {
    const classes = useStyles();


    let ListOfQuizzes = null;

    if (props.quizzes.length > 0) {
        ListOfQuizzes = props.quizzes.map((quiz) => {
            return (
                <TableRow key={quiz._id}>
                    <TableCell component="th" scope="row">
                        {quiz.name}
                    </TableCell>
                    <TableCell align="right">
                        <ButtonGroup>
                            <Button
                                variant="contained"
                                color="secondary"
                                startIcon={<FormatColorText/>}
                                onClick={() => props.quizIt(quiz._id)}
                            >Quiz it!</Button>
                        </ButtonGroup>
                    </TableCell>
                </TableRow>
            );
        });
    }

    return (
        <div className={classes.quizlist}>
            <Typography className={classes.heading} align="center" variant="h4"
                        component="h2">{props.title}</Typography>
            {props.quizzes.length > 0 ?
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableBody>
                            {ListOfQuizzes}
                        </TableBody>
                    </Table>
                </TableContainer> :
                <TableRow className={classes.error}><CustomAlert text="There are no quizzes!" noMargin={true} status="error"/></TableRow>
            }
        </div>
    )
}

export default QuizList;