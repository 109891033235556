import React from 'react';
import Alert from '@material-ui/lab/Alert';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
        customAlert: {
            marginBottom: 20
        },
        customAlertNoMargin: {
            marginBottom: 0
        }
    }
));

function CustomAlert(props) {
    const classes = useStyles();

    return(
        <Alert className={props.noMargin ? classes.customAlertNoMargin : classes.customAlert} severity={props.status}>{props.text}</Alert>
    );
}

export default CustomAlert;