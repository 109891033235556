import React from 'react';
import {makeStyles} from '@material-ui/core/styles'
import {Link} from 'react-router-dom';
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
    navbar: {
        width: '100%',
        marginBottom: 20
    }
}));

function Navbar() {
    const classes = useStyles();

    return (
        <div className={classes.navbar}>
            <Typography align="center" variant="h6"><Link to={'/dashboard'}>Dashboard</Link></Typography>
        </div>
    )
}

export default Navbar;