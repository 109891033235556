import React from 'react';
import {HashRouter} from 'react-router-dom';
import {ThemeProvider} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Theme from './styling/Theme';
import Navbar from './components/layout/Navbar';
import Router from './components/routing/Router';
import AuthVerification from "./components/utils/AuthVerification";

function App() {
    let isAuthenticated = AuthVerification();

    return (
        <ThemeProvider theme={Theme}>
            <div className="App">
                <HashRouter>
                    {isAuthenticated ? <Navbar/> : ''}
                    <Container maxWidth="md">
                        <Router/>
                    </Container>
                </HashRouter>
            </div>
        </ThemeProvider>
    );
}

export default App;
