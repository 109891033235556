import React, {useState} from 'react';
import {Redirect, Link} from "react-router-dom";
import Cookies from 'js-cookie';
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import axios from "axios";
import AuthVerification from "../../utils/AuthVerification";
import TableRow from "@material-ui/core/TableRow";
import Container from "@material-ui/core/Container";
import Done from '@material-ui/icons/Done';
import Error from '@material-ui/icons/Error';
import CustomAlert from "../../utils/CustomAlert";

const useStyles = makeStyles((theme) => ({
        heading: {
            marginTop: 20,
            marginBottom: 10
        },
        username: {
            marginBottom: 10
        },
        answer: {
            marginBottom: 20
        },
        answerHeading: {
            marginBottom: 10
        },
        answerText: {
            marginBottom: 10,
            display: 'flex'
        },
        answerErrorText: {
            marginBottom: 10,
            color: theme.palette.error.main
        },
        answerBorder: {
            marginBottom: 20
        },
        backLink: {
            width: '100%',
            textAlign: "center",
            marginBottom: 40
        },
        error: {
            width: '100%',
            display: 'block'
        },
        errorIcon: {
            fill: theme.palette.error.main,
            marginRight: 10
        },
        correctIcon: {
            fill: theme.palette.success.main,
            marginRight: 10
        }
}));

function SingleResult(props) {
    const classes = useStyles();
    let isAuthenticated = AuthVerification();

    let [quizAnswers, setQuizAnswers] = useState([]);
    let [quizTitle, setQuizTitle] = useState('');
    let [quizSender, setQuizSender] = useState('');
    let [quizLoaded, setQuizLoaded] = useState(false);
    let [answersLoaded, setAnswersLoaded] = useState(false);

    async function getQuiz() {
        try {
            setQuizLoaded(true);

            const quizResponse = await axios.get(process.env.REACT_APP_SINGLE_QUIZ_URL + '/' + props.match.params.quizId, {
                headers: {
                    Authorization: Cookies.get('auth')
                }
            });

            setQuizTitle(quizResponse.data.data.name);
        } catch (err) {
            console.log(err.response);
        }
    }

    async function getQuizAnswers() {
        try {
            setAnswersLoaded(true);

            const answerResponse = await axios.get(
                process.env.REACT_APP_GET_SINGLE_QUIZRESULT_URL + '/' + props.match.params.quizId + '/' + props.match.params.questionId, {
                    headers: {
                        Authorization: Cookies.get('auth')
                    }
                });

            console.log(answerResponse.data.data.answeredQuestions);

            setQuizSender(answerResponse.data.data.senderName);
            setQuizAnswers(answerResponse.data.data.answeredQuestions);
        } catch (err) {
            console.log(err.response);
        }
    }


    let Answers = null;


    if (quizAnswers.length > 0) {
        Answers = quizAnswers.map((answer, index) => {
            return (
                <div className={classes.answer} key={answer._id}>
                    <Typography className={classes.answerHeading} variant="h6">{answer.question}</Typography>
                    <Typography className={classes.answerText} variant="body1">
                        {answer.questionAnswer !== answer.correctAnswer ?
                            <Error className={classes.errorIcon}/> :
                            <Done className={classes.correctIcon}/>
                        }
                        {answer.questionAnswer}
                    </Typography>
                    {answer.questionAnswer !== answer.correctAnswer ? <Typography className={classes.answerErrorText} variant="body1">
                        {answer.correctAnswer}
                    </Typography> : ''}
                    <hr className={classes.answerBorder}/>
                </div>
            );
        });
    }

    if (!quizLoaded) {
        getQuiz();
    }

    if (!answersLoaded) {
        getQuizAnswers();
    }

    return (
        <>
            {!isAuthenticated ? <Redirect to='/login'/> : ''}
            <Typography className={classes.heading} align="center" variant="h4"
                        component="h1">{quizTitle}</Typography>
            <Typography className={classes.username} align="center" variant="h5"
                        component="h2">{quizSender}</Typography>
            <Typography className={classes.backLink} variant="body1"><Link
                to={'/results/' + props.match.params.quizId} align="center">Back to {quizTitle}</Link></Typography>
            <Container maxWidth="sm">
                {quizAnswers.length > 0 ? Answers :
                    <TableRow className={classes.error}><CustomAlert text="There are no results!" noMargin={true} status="error"/></TableRow>}
            </Container>
        </>
    );
}

export default SingleResult;