import React from 'react';
import {Redirect} from 'react-router-dom';
import {useState} from 'react';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import {Link} from "react-router-dom";
import axios from 'axios';
import CustomAlert from "../../utils/CustomAlert";
import AuthVerification from "../../utils/AuthVerification";


const useStyles = makeStyles((theme) => ({
        register: {
            marginTop: 50
        },
        heading: {
            marginBottom: 20
        },
        input: {
            width: '100%',
            marginBottom: 20
        },
        submit: {
            width: '100%',
            marginBottom: 30
        },
        customAlert: {
            marginBottom: 20
        }
    }
));

function Register() {
    let isAuthenticated = AuthVerification();

    const classes = useStyles();

    let [username, setUsername] = useState('');
    let [email, setEmail] = useState('');
    let [password, setPassword] = useState('');
    let [alertStatus, setAlertStatus] = useState(false);
    let [alert, setAlert] = useState({});
    let [redirect, setRedirect] = useState(false);

    async function register(e) {
        e.preventDefault();

        try {
            const registerData = {
                username,
                email,
                password
            };

            await axios.post(process.env.REACT_APP_REGISTER_URL, registerData);
            setRedirect(true);
        } catch (err) {
            const alertObject = {
                'text': err.response.data.error,
                'status': 'error'
            };

            setAlert(alertObject);
            setAlertStatus(true);
        }
    }

    return (
        <>
            {isAuthenticated ? <Redirect to='/dashboard' /> : ''}
            {redirect ? <Redirect to='/login' /> : ''}
            <div className={classes.register}>
                <Container maxWidth="sm">
                    {alertStatus ? <CustomAlert text={alert.text} status={alert.status}/> : ''}
                    <Typography className={classes.heading} align="center" variant="h4" component="h1">Register a new account</Typography>
                    <form onSubmit={register}>
                        <TextField required
                                   className={classes.input}
                                   type="text"
                                   label="Username"
                                   value={username}
                                   onChange={(e) => setUsername(e.target.value)}/>
                        <TextField required
                                   className={classes.input}
                                   type="email"
                                   label="Email"
                                   value={email}
                                   onChange={(e) => setEmail(e.target.value)}/>
                        <TextField required
                                   className={classes.input}
                                   type="password"
                                   label="Password"
                                   value={password}
                                   minLength="8"
                                   onChange={(e) => setPassword(e.target.value)}/>
                        <Button variant="contained" className={classes.submit} color="secondary" type="submit">Register</Button>
                    </form>
                    <Typography variant="body1" align="center" gutterBottom>
                        Already have an account?<br/>
                        <Link to={'/login'}>Login</Link>
                    </Typography>
                </Container>
            </div>
        </>
    );
}

export default Register;