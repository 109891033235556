import React, {useState} from 'react';
import {Redirect, Link} from "react-router-dom";
import Cookies from 'js-cookie';
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import axios from "axios";
import AuthVerification from "../../utils/AuthVerification";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import CustomAlert from "../../utils/CustomAlert";

const useStyles = makeStyles((theme) => ({
        heading: {
            marginTop: 20,
            marginBottom: 20
        },
        error: {
            width: '100%',
            display: 'block'
        }
    }
));

function ListResults(props) {
    const classes = useStyles();
    let isAuthenticated = AuthVerification();

    let [quizResults, setQuizResults] = useState([]);
    let [quizTitle, setQuizTitle] = useState('');
    let [quizLoaded, setQuizLoaded] = useState(false);
    let [resultsLoaded, setResultsLoaded] = useState(false);

    async function getQuiz() {
        try {
            setQuizLoaded(true);

            const quizResponse = await axios.get(process.env.REACT_APP_SINGLE_QUIZ_URL + '/' + props.match.params.quizId, {
                headers: {
                    Authorization: Cookies.get('auth')
                }
            });

            setQuizTitle(quizResponse.data.data.name);
        } catch (err) {
            console.log(err.response);
        }
    }

    async function getQuizResults() {
        try {
            setResultsLoaded(true);

            const quizResponse = await axios.get(process.env.REACT_APP_GET_ALL_QUIZRESULTS_URL + '/' + props.match.params.quizId, {
                headers: {
                    Authorization: Cookies.get('auth')
                }
            });

            setQuizResults(quizResponse.data.data);
        } catch (err) {
            console.log(err.response);
        }
    }


    let Results = null;


    if (quizResults.length > 0) {
        Results = quizResults.map((result) => {
            return (
                <TableRow key={result._id}>
                    <TableCell component="th" scope="row">
                        <Typography variant="body1"><Link
                            to={'/results/' + props.match.params.quizId + '/' + result._id}>{result.senderName}</Link></Typography>
                    </TableCell>
                    <TableCell align="right">
                        <Typography variant="body1">{result.answeredQuestions.length} Fragen beantwortet</Typography>
                    </TableCell>
                </TableRow>
            );
        });
    }

    if (!quizLoaded) {
        getQuiz();
    }

    if (!resultsLoaded) {
        getQuizResults();
    }

    return (
        <>
            {!isAuthenticated ? <Redirect to='/login'/> : ''}
            <Typography className={classes.heading} align="center" variant="h4"
                        component="h1">{quizTitle}</Typography>
            {quizResults.length > 0 ?
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableBody>
                            {Results}
                        </TableBody>
                    </Table>
                </TableContainer> :
                <TableRow className={classes.error}><CustomAlert text="There are no results!" noMargin={true}
                                                                 status="error"/></TableRow>
            }
        </>
    );
}

export default ListResults;