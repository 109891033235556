import React, {useState} from 'react';
import {Redirect} from "react-router-dom";
import Cookies from 'js-cookie';
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Container from '@material-ui/core/Container';
import Button from "@material-ui/core/Button";
import Done from '@material-ui/icons/Done';
import Error from '@material-ui/icons/Error';
import axios from "axios";
import AuthVerification from "../../utils/AuthVerification";

const useStyles = makeStyles((theme) => ({
        heading: {
            marginTop: 20,
            marginBottom: 20
        },
        addQuizForm: {
            marginBottom: 40
        },
        quizTitle: {
            marginTop: 40
        },
        quizTitleInput: {
            marginBottom: 40,
            width: '100%'
        },
        inputGroup: {
            marginBottom: 20,
            width: '100%'
        },
        inputHeading: {
            marginBottom: 5
        },
        input: {
            width: '100%'
        },
        hr: {
            margin: '20px 0 30px 0'
        },
        checkResult: {
            width: '100%'
        },
        submit: {
            width: '100%'
        }
    }
));

function QuizIt(props) {
    const classes = useStyles();
    let isAuthenticated = AuthVerification();

    let [quizFormFields, setQuizFormFields] = useState([]);
    let [quizTitle, setQuizTitle] = useState('');
    let [redirect, setRedirect] = useState(false);
    let [quizLoaded, setQuizLoaded] = useState(false);

    async function getQuiz() {
        try {
            setQuizLoaded(true);

            const quizResponse = await axios.get(process.env.REACT_APP_SINGLE_QUIZ_URL + '/' + props.match.params.quizId, {
                headers: {
                    Authorization: Cookies.get('auth')
                }
            });

            let questions = [];

            quizResponse.data.data.questions.forEach(question => {
                let questionItem = {
                    question: question.question,
                    questionAnswer: '',
                    correctAnswer: question.correctAnswer,
                    checked: false,
                    incorrect: false
                }
                questions.push(questionItem);
            });
            setQuizTitle(quizResponse.data.data.name);
            setQuizFormFields(questions);
        } catch (err) {
            console.log(err.response);
        }
    }

    async function saveQuizResult(e) {
        e.preventDefault();

        try {
            const quizResultData = {
                answeredQuestions: quizFormFields
            }

            await axios.post(process.env.REACT_APP_ADD_QUIZRESULT_URL + '/' + props.match.params.quizId, quizResultData, {
                headers: {
                    Authorization: Cookies.get('auth')
                }
            });

            setRedirect(true);
        } catch (err) {
            console.log(err);
        }
    }

    function checkResult(i) {
        let quizQuestions = quizFormFields;
        const correctAnswer = quizQuestions[i].correctAnswer;
        const questionAnswer = quizQuestions[i].questionAnswer;
        quizQuestions[i].checked = true;
        quizQuestions[i].incorrect = correctAnswer !== questionAnswer;

        setQuizFormFields([...quizQuestions]);
    }

    const FormFields = quizFormFields.map((question, index) => {
        function setValueOfFormField(value) {
            let array = [...quizFormFields];
            array[index].questionAnswer = value;
            setQuizFormFields(array);
        }

        console.log(quizFormFields[index].checked);

        return (
            <div className={classes.question} key={index}>
                <Typography variant="body1" gutterBottom>
                    {question.question}
                </Typography>
                <div className={classes.inputGroup}>
                    <TextField required
                               className={classes.input}
                               type="text"
                               value={quizFormFields[index].questionAnswer}
                               onChange={(e) => setValueOfFormField(e.target.value)}
                               InputProps={{
                                   endAdornment: <InputAdornment
                                       position="end">{quizFormFields[index].checked ? (quizFormFields[index].incorrect ?
                                       <Error/> : <Done/>) : ''}</InputAdornment>,
                               }}/>
                </div>
                {question.incorrect ? <div className={classes.inputGroup}>
                    <TextField required
                               className={classes.input}
                               error
                               type="text"
                               label="Antwort"
                               InputProps={{
                                   readOnly: true,
                               }}
                               value={quizFormFields[index].correctAnswer}/>
                </div> : ''}
                <Button variant="contained" className={classes.checkResult} color="default"
                        onClick={() => checkResult(index)}>Check Result of Question</Button>
                <hr className={classes.hr}/>
            </div>
        );
    });

    if (!quizLoaded) {
        getQuiz();
    }

    return (
        <>
            {!isAuthenticated ? <Redirect to='/login'/> : ''}
            {redirect ? <Redirect to='/dashboard'/> : ''}
            <Typography className={classes.heading} align="center" variant="h4"
                        component="h1">{quizTitle}</Typography>
            <Container maxWidth="sm">
                <form className={classes.addQuizForm} onSubmit={(e) => saveQuizResult(e)}>
                    {FormFields}
                    <Button variant="contained" className={classes.submit} color="secondary" type="submit">Send your
                        answers!</Button>
                </form>
            </Container>
        </>
    );
}

export default QuizIt;