import React from 'react';
import {Redirect} from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import Container from "@material-ui/core/Container";
import {makeStyles} from '@material-ui/core/styles';
import AuthVerification from "../../utils/AuthVerification";

const useStyles = makeStyles((theme) => ({
        checkEmail: {
            marginTop: 50
        }
    }
));

function CheckEmail() {
    let isAuthenticated = AuthVerification();

    const classes = useStyles();

    return (
        <>
            {isAuthenticated ? <Redirect to='/dashboard' /> : ''}
            <div className={classes.checkEmail}>
                <Container maxWidth="sm">
                    <Alert severity="success">Please check your email account! You should have received an email to reset your password!</Alert>
                </Container>
            </div>
        </>
    );
}

export default CheckEmail;