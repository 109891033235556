import React from 'react';
import {Redirect} from 'react-router-dom';
import {useState} from 'react';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import axios from 'axios';
import CustomAlert from "../../utils/CustomAlert";
import AuthVerification from "../../utils/AuthVerification";

const useStyles = makeStyles((theme) => ({
        forgotPassword: {
            marginTop: 50
        },
        input: {
            width: '100%',
            marginBottom: 20
        },
        submit: {
            width: '100%',
            marginBottom: 30
        }
    }
));

function ResetPassword(props) {
    let isAuthenticated = AuthVerification();

    const classes = useStyles();

    let [password, setPassword] = useState('');
    let [redirect, setRedirect] = useState(false);
    let [alert, setAlert] = useState({});
    let [alertStatus, setAlertStatus] = useState(false);

    async function forgotPassword(e) {
        e.preventDefault();

        try {
            const resetData = {
                password
            };

            await axios.put(process.env.REACT_APP_RESETPASSWORD_URL + '/' + props.match.params.resetPasswordToken, resetData);

            setRedirect(true);
        } catch (err) {
            const alertObject = {
                'text': err.response.data.error,
                'status': 'error'
            };

            setAlert(alertObject);
            setAlertStatus(true);
        }
    }

    return (
        <>
            {isAuthenticated ? <Redirect to='/dashboard' /> : ''}
            {redirect ? <Redirect to='/login' /> : ''}
            <div className={classes.login}>
                <Container maxWidth="sm">
                    {alertStatus ? <CustomAlert text={alert.text} status={alert.status}/> : ''}
                    <Typography className={classes.heading} align="center" variant="h4" component="h1">Set your new password</Typography>
                    <form onSubmit={forgotPassword}>
                        <TextField required
                                   className={classes.input}
                                   type="password"
                                   label="New Password"
                                   value={password}
                                   onChange={(e) => setPassword(e.target.value)}/>
                        <Button variant="contained" className={classes.submit} color="secondary" type="submit">Set Password</Button>
                    </form>
                </Container>
            </div>
        </>
    );
}

export default ResetPassword;