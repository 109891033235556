import React from 'react';
import {Switch, Route} from 'react-router-dom';

import Home from '../views/Home';
import Register from '../views/auth/Register';
import Login from '../views/auth/Login';
import ForgotPassword from '../views/auth/ForgotPassword';
import ResetPassword from '../views/auth/ResetPassword';
import CheckEmail from '../views/auth/CheckEmail';

import Dashboard from "../views/private/Dashboard";

import AddQuiz from "../views/quiz/AddQuiz";
import EditQuiz from "../views/quiz/EditQuiz";

import QuizIt from "../views/results/QuizIt";
import ListResults from "../views/results/ListResults";
import SingleResult from "../views/results/SingleResult";

function Router() {
    return (
        <Switch>
            <Route path={'/'} exact><Home/></Route>
            <Route path={'/login'}><Login/></Route>
            <Route path={'/register'}><Register/></Route>
            <Route path={'/forgot-password'} exact><ForgotPassword/></Route>
            <Route path={'/forgot-password/check-url'}><CheckEmail/></Route>
            <Route path={'/reset-password/:resetPasswordToken'} render={(props) => <ResetPassword {...props} />}/>

            <Route path={'/dashboard'}><Dashboard/></Route>

            <Route path={'/add-quiz'}><AddQuiz/></Route>
            <Route path={'/edit-quiz/:quizId'} render={(props) => <EditQuiz {...props} />}/>

            <Route path={'/quiz-it/:quizId'} render={(props) => <QuizIt {...props} />}/>

            <Route path={'/results/:quizId'} render={(props) => <ListResults {...props} />} exact/>
            <Route path={'/results/:quizId/:questionId'} render={(props) => <SingleResult {...props} />}/>
        </Switch>
    )
}

export default Router;