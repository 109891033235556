import React from 'react';
import {Redirect} from 'react-router-dom';
import {useState} from 'react';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import axios from 'axios';
import CustomAlert from "../../utils/CustomAlert";
import AuthVerification from "../../utils/AuthVerification";

const useStyles = makeStyles((theme) => ({
        forgotPassword: {
            marginTop: 50
        },
        input: {
            width: '100%',
            marginBottom: 20
        },
        submit: {
            width: '100%',
            marginBottom: 30
        }
    }
));

function ForgotPassword() {
    let isAuthenticated = AuthVerification();

    const classes = useStyles();

    let [email, setEmail] = useState('');
    let [redirect, setRedirect] = useState(false);
    let [alert, setAlert] = useState({});
    let [alertStatus, setAlertStatus] = useState(false);

    async function forgotPassword(e) {
        e.preventDefault();

        try {
            const resetData = {
                email
            };

            await axios.post(process.env.REACT_APP_FORGOTPASSWORD_URL, resetData);

            setRedirect(true);
        } catch (err) {
            const alertObject = {
                'text': err.response.data.error,
                'status': 'error'
            };

            setAlert(alertObject);
            setAlertStatus(true);
        }
    }

    return (
        <>
            {isAuthenticated ? <Redirect to='/dashboard' /> : ''}
            {redirect ? <Redirect to='/forgot-password/check-url' /> : ''}
            <div className={classes.login}>
                <Container maxWidth="sm">
                    {alertStatus ? <CustomAlert text={alert.text} status={alert.status}/> : ''}
                    <Typography className={classes.heading} align="center" variant="h4" component="h1">Reset your password</Typography>
                    <form onSubmit={forgotPassword}>
                        <TextField required
                                   className={classes.input}
                                   type="email"
                                   label="Email"
                                   value={email}
                                   onChange={(e) => setEmail(e.target.value)}/>
                        <Button variant="contained" className={classes.submit} color="secondary" type="submit">Reset Password</Button>
                    </form>
                </Container>
            </div>
        </>
    );
}

export default ForgotPassword;