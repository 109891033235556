import React, {useState} from 'react';
import {Redirect} from "react-router-dom";
import Cookies from 'js-cookie';
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Button from "@material-ui/core/Button";
import axios from "axios";
import AuthVerification from "../../utils/AuthVerification";

const useStyles = makeStyles((theme) => ({
        heading: {
            marginBottom: 20
        },
        addQuizForm: {
            marginBottom: 40
        },
        quizTitle: {
            marginTop: 40
        },
        quizTitleInput: {
            marginBottom: 40,
            width: '100%'
        },
        inputGroup: {
            marginBottom: 20,
            width: '100%'
        },
        inputHeading: {
            marginBottom: 5
        },
        input: {
            width: '100%'
        },
        inputCorrectAnswer: {
            width: '100%',
            marginTop: 20
        },
        remove: {
            width: '100%',
            margin: '20px 0 0 0'
        },
        hr: {
            margin: '20px 0 30px 0'
        },
        addField: {
            margin: '0 0 20px 0',
            width: '100%'
        },
        submit: {
            width: '100%'
        }
    }
));

function AddQuiz() {
    const classes = useStyles();
    let isAuthenticated = AuthVerification();

    const defaultQuizFormField = {
        question: '',
        correctAnswer: '',
        questionType: 'text'
    };

    let [quizFormFields, setQuizFormFields] = useState([defaultQuizFormField]);
    let [quizTitle, setQuizTitle] = useState('');
    let [redirect, setRedirect] = useState(false);

    function addField() {
        let array = [...quizFormFields];
        array.push(defaultQuizFormField);
        setQuizFormFields(array);
    }

    function removeField(index) {
        let array = [...quizFormFields];
        array.splice(index, 1);
        setQuizFormFields(array);
    }

    async function addQuiz(e) {
        e.preventDefault();

        try {
            const quizData = {
                name: quizTitle,
                questions: quizFormFields
            }

            await axios.post(process.env.REACT_APP_ADD_QUIZ_URL, quizData, {
                headers: {
                    Authorization: Cookies.get('auth')
                }
            });

            setRedirect(true);
        } catch (err) {
            console.log(err);
        }
    }

    const FormFields = quizFormFields.map((question, index) => {

        function setValueOfFormField(value) {
            let array = [...quizFormFields];
            array[index].question = value;
            setQuizFormFields(array);
        }

        function setValueOfFormFieldResult(value) {
            let array = [...quizFormFields];
            array[index].correctAnswer = value;
            setQuizFormFields(array);
        }

        return (
            <div className={classes.question} key={index}>
                <div className={classes.inputGroup}>
                    <TextField required
                               className={classes.input}
                               type="text"
                               label={'Frage ' + (index + 1)}
                               value={quizFormFields[index].question}
                               onChange={(e) => setValueOfFormField(e.target.value)}/>
                    <TextField required
                               className={classes.inputCorrectAnswer}
                               type="text"
                               label={'Antwort zu Frage ' + (index + 1)}
                               value={quizFormFields[index].correctAnswer}
                               onChange={(e) => setValueOfFormFieldResult(e.target.value)}/>
                    {index > 0 ? <Button variant="contained" className={classes.remove} color="default"
                                         onClick={() => removeField(index)}>Remove Question</Button> : ''}
                </div>
                <hr className={classes.hr}/>
            </div>
        );
    });

    return (
        <>
            {!isAuthenticated ? <Redirect to='/login' /> : ''}
            {redirect ? <Redirect to='/dashboard' /> : ''}
            <Typography className={classes.heading} align="center" variant="h4"
                        component="h1">Add new quiz!</Typography>
            <Container maxWidth="sm">
                <form className={classes.addQuizForm} onSubmit={addQuiz}>
                    <Typography className={classes.quizTitle} align="center" variant="h5"
                                component="h2">Quiz-Title</Typography>
                    <TextField required
                               className={classes.quizTitleInput}
                               type="text"
                               label="Quiz-Title"
                               value={quizTitle}
                               onChange={(e) => setQuizTitle(e.target.value)}/>
                    <Typography className={classes.questionsHeading} align="center" variant="h5"
                                component="h2">Questions</Typography>
                    {FormFields}
                    <Button variant="contained" className={classes.addField} color="primary" onClick={addField}>Add
                        Question</Button>
                    <Button variant="contained" className={classes.submit} color="secondary" type="submit">Save
                        Quiz</Button>
                </form>
            </Container>
        </>
    );
}

export default AddQuiz;